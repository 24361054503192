export const addZero = (num: string | number): string => {
  if (typeof num === "number" && num > 999) {
    return num.toString();
  }
  if (typeof num === "string" && num.length > 3) {
    return num;
  }
  return ("000" + num).slice(-3);
};

export const addTwoZero = (num: number): string => {
  return ("000" + num).slice(-2);
};
