import services from "@/services";
import { Button, PageHeader, Space, Spin, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { CloudDownloadOutlined, PrinterOutlined } from "@ant-design/icons";
import { downloadManifest } from "@/common/utils/sheetExportByUser";
import { downloadMiniInvoice } from "@/common/utils/miniSheetExportByUser";
import { useParties } from "@/store";
import { downloadProducts } from "@/common/utils/chinaSheetExport";
import InvoicePdf from "@/common/components/InvoicePdf";
import { useReactToPrint } from "react-to-print";
import {
  getProductsTotalCount,
  getProductsTotalPrice,
  getProductsTotalWeight,
} from "@/common/utils/getUser";
import _ from "lodash";
import { downloadBulkInvoices } from "@/common/utils/donwloadBulkInvoice";

const InvoiceByUsers = () => {
  const navigate = useNavigate();

  const party = useParties(({ party }) => party);

  const [data, setData] = useState([]);

  const [userState, setUserState] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const componentRef = useRef();

  const { data: users, isLoading: usersLoading } = useQuery(
    ["getUsersByProducts"],
    () => services.user.getUsersByProducts()
  );

  const { data: groups, isLoading: groupsLoading } = useQuery(
    ["getGroupsByParty", party],
    () => {
      return services.cell.getCellGroupsByParty(party.id);
    },
    {
      enabled: !!party?.id,
    }
  );

  const [userIds, setUserIds] = useState([]);

  // useEffect(() => {
  //   if (users?.length > 0) {
  //     const userIds = _.map(users, (user: any) => ({
  //       text: user?.phone_extra,
  //       value: user?.phone_extra,
  //     }));
  //     const _users = _.map(users, (user: any, index: number) => ({
  //       ...user,
  //       index: index + 1,
  //     }));
  //     setData(_users);
  //     setUserIds(userIds);
  //   }
  // }, [users]);

  useEffect(() => {
    if (groups?.length > 0) {
      const _users = _.map(groups, (group: any, index: number) => ({
        ...group?.user,
        index: "" + group?.cell?.cellNumber + "" + group?.id,
        products: group?.products,
        cell: group?.cell,
        recipient: group.recipient,
        isSeparately: group.isSeparately,
      }));

      setData(_users);

      const userIds = _.chain(_users)
        .uniqBy("id")
        .map((item) => ({
          text: item?.phone_extra,
          value: item?.phone_extra,
        }))
        .value();

      setUserIds(userIds);
    }
  }, [groups]);

  const state: any = {
    bordered: true,
    loading: false,
    size: "small",
    title: undefined,
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
    pagination: false,
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "№",
        dataIndex: "_index",
        key: "id",
        render: (text, record, index) => index + 1,
      },
      { title: "Track", dataIndex: "track_number", key: "track" },
      {
        title: "Cell",
        dataIndex: "cell",
        key: "cell.id",
        render: (text) => `Cell${record?.cell?.cellNumber}`,
      },
      { title: "Name rus", dataIndex: "name_ru", key: "name_ru" },
      { title: "Name", dataIndex: "name", key: "name" },
      { title: "Weight", dataIndex: "weight", key: "weight" },
      { title: "Count", dataIndex: "count", key: "count" },
      { title: "Price", dataIndex: "price", key: "price" },
      {
        title: "Created date",
        dataIndex: "created_at",
        key: "created_at",
        render: (text) => dayjs(text).format("DD MMM YYYY, HH:mm"),
      },
    ];

    const data = record?.products;

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  useEffect(() => {
    if (userState?.id) {
      handlePrint();
      setUserState({});
    }
  }, [party, userState]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="container">
      {userState?.id && (
        <div style={{ display: "none" }}>
          <div id="pdf" ref={componentRef}>
            <InvoicePdf user={userState} party={party} />
          </div>
        </div>
      )}
      <div>
        <div className="scan-header">
          <PageHeader
            ghost={false}
            title={"Parties"}
            onBack={() => navigate("/")}
          />
        </div>
        <Button
          type="dashed"
          style={{ marginTop: "1rem" }}
          size="large"
          icon={<CloudDownloadOutlined />}
          onClick={() => {
            // mutateByParty(party?.id);
            downloadManifest({ users: data, party });
          }}
        >
          Download Manifest for {party?.number}
        </Button>
        <Button
          type="dashed"
          style={{ marginTop: "1rem", marginLeft: "2rem" }}
          size="large"
          icon={<CloudDownloadOutlined />}
          onClick={() => {
            // mutateByParty(party?.id);
            downloadProducts({ users: data, party });
          }}
        >
          Download products for {party?.number}
        </Button>

        <Button
          type="default"
          style={{ marginTop: "1rem", marginLeft: "2rem" }}
          size="large"
          icon={<CloudDownloadOutlined />}
          onClick={() => {
            downloadBulkInvoices({ users: data, party });
          }}
        >
          Download all invoices {party?.number}
        </Button>

        <Spin spinning={usersLoading}>
          <div style={{ margin: "1rem", minHeight: "100vh" }}>
            <Table
              rowKey={"index"}
              dataSource={data || []}
              size="small"
              loading={usersLoading}
              expandable={{
                expandedRowRender: expandedRowRender,
                columnWidth: 40,
                showExpandColumn: true,
                onExpand: (expanded) => console.log(expanded),
                onExpandedRowsChange: (rows) => {
                  if (rows.length > 1) {
                    setExpandedRowKeys([rows[rows?.length - 1]]);
                    return;
                  } else {
                    setExpandedRowKeys(rows);
                  }
                },
                expandedRowKeys: expandedRowKeys,
              }}
              {...state}
            >
              <Table.Column
                title="UserID"
                dataIndex="phone_extra"
                key="phone_extra"
                width={120}
                render={(text) => <strong>{text}</strong>}
                sorter={(a, b) => +a.phone_extra - +b.phone_extra}
                filters={userIds}
                filterSearch={true}
                onFilter={(value, record): boolean =>
                  record.phone_extra === value
                }
              />
              <Table.Column
                title="Cell"
                dataIndex="cell"
                key="products_cell"
                width={120}
                render={(text, record) => {
                  return record?.isSeparately ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      S {text?.cellNumber}
                    </span>
                  ) : (
                    `cell ${text?.cellNumber}`
                  );
                }}
              />
              <Table.Column
                title="Products count"
                dataIndex="products"
                key="products_count"
                width={120}
                render={(text) => getProductsTotalCount(text)}
              />
              <Table.Column
                title="Total weight"
                dataIndex="products"
                key="products_weight"
                width={120}
                render={(text) => `${getProductsTotalWeight(text) / 1000} kg`}
              />
              <Table.Column
                title="Total price"
                dataIndex="products"
                key="products_price"
                width={120}
                render={(text) => `${getProductsTotalPrice(text) / 1000} $`}
              />
              <Table.Column
                title="Action"
                key="action"
                render={(text, record, index) => (
                  <Space size={"middle"} align="start" direction="horizontal">
                    <Button
                      type="primary"
                      style={{ marginBottom: "5px" }}
                      icon={<PrinterOutlined />}
                      onClick={() => {
                        if (party) {
                          setUserState(record);
                        }
                      }}
                    >
                      Print Invoice
                    </Button>
                    <Button
                      type="dashed"
                      style={{ marginBottom: "5px" }}
                      icon={<CloudDownloadOutlined />}
                      onClick={() => {
                        if (party) {
                          downloadMiniInvoice({ user: record, party });
                        }
                      }}
                    >
                      Download Invoice excel
                    </Button>
                  </Space>
                )}
              />
            </Table>
          </div>
        </Spin>
      </div>
    </div>
  );
};
export default InvoiceByUsers;
